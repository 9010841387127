<template>
  <div>
    <nav-header @envchangedata="handlerdata"></nav-header>
    <div class="main">
      <div :class="lang">
        <div class="target envCon ">
          <div class="feature target-left pub clearfix">
            <h4 v-if="this.$i18n.locale == 'zh'">绿色成果</h4>
            <h4 v-if="this.$i18n.locale == 'en'">GREEN</h4>
            <h4 v-if="this.$i18n.locale == 'en'" style="margin-top: -15px">ACHIEVEMENTS </h4>
            <div  class="feature-margin-left">
              <div class="target-detail">
                <span class="num">{{envcontent.power}}</span>
                <span class="target-detail-text">{{$t('env.yidu')}}</span>
                <!--                <span class="target-detail-span">{{$t('env.enery')}}</span>-->
                <span  class="target-detail-span" v-if="this.$i18n.locale == 'zh'">{{envcontent.achievementstime}}年节能</span>
                <span  class="target-detail-span" v-if="this.$i18n.locale == 'en'">{{envcontent.achievementstime}} Energy saving</span>

              </div>
              <div class="target-detail">
                <span class="num">{{envcontent.ton}}</span>
                <span class="target-detail-text">{{$t('env.achnumContent')}}</span>
                <!--                <span class="target-detail-span">{{$t('env.reduction')}}</span>-->
                <span  class="target-detail-span" v-if="this.$i18n.locale == 'zh'">{{envcontent.achievementstime}}年减碳</span>
                <span  class="target-detail-span" v-if="this.$i18n.locale == 'en'">{{envcontent.achievementstime}} Carbon emission reduction</span>
              </div>
              <div class="target-detail target-detail-bottom">
                <span style="display: block; margin-bottom: -10px;" >{{$t('env.equivalent')}}</span>
                <span class="num">{{envcontent.tree}}</span>
                <span class="target-detail-text">{{$t('env.equivaContent')}}</span>
                <span class="target-detail-span">
                   {{$t('env.tree1')}}
                   <font size="3" id="fonts">2</font>
                  {{$t('env.tree2')}}</span>
              </div>
            </div>

          </div>
          <div class="feature target-right pub" id="target-right-margin">
            <h4>{{$t('env.target')}}</h4>
            <span class="target-right-year">{{$t('env.targetYear')}}</span>
            <div class="target-right-img">
              <img src="/img/env/tree.png" >
            </div>
            <div >
              <div class="target-detail mobile-target">
                <span class="num">{{envcontent.everyton}}</span>
                <span class="target-detail-text">{{$t('env.tarnumdetail')}}</span>
                <span class="target-detail-span target-detail-span1">{{$t('env.targetdetail')}}</span>


              </div>
              <div class="target-detail mobile-target">

                <span class="num">{{envcontent.totalton}}</span>
                <span class="target-detail-text">{{$t('env.tarnumdetail1')}}</span>
                <span class="target-detail-span target-detail-span1">{{$t('env.targetdetail1')}}</span>
              </div>

            </div>

          </div>
        </div>
        <!-- 总耗能 -->
        <div class="consumeEnery envCon">
          <div class="consumeEnery-title feature">
            <h4 v-if="this.$i18n.locale == 'zh'">项目总能耗</h4>
            <h4 v-if="this.$i18n.locale == 'en'">TOTAL PROJECT ENERGY</h4>
            <h4 v-if="this.$i18n.locale == 'en'" style="margin-top: -15px">CONSUMPTION </h4>
            <p>{{envcontent.bythetime}}</p>
          </div>
          <div class="consumeEnery-total feature">
            <span  class="sonsume-conspan total-conspan">{{envcontent.projectnum}}{{$t('env.projectsmall')}}</span>
            <span class="consume-num">{{envcontent.totalsave}}</span>
            <span class="consume-detail">{{$t('env.pnum2')}}</span>
          </div>

          <ul class="consumeEnery-detail feature">
            <li>
              <span  class="sonsume-conspan">{{$t('env.pleft')}}</span>
              <span class="consume-num">{{envcontent.cumulativesave}}</span>
              <span class="consume-detail">{{$t('env.pnum2')}}</span>

            </li>
            <li>
              <span  class="sonsume-conspan">{{$t('env.pcenter')}}</span>
              <span class="consume-num">{{envcontent.saverate}}</span>
              <span class="consume-detail">%</span>
            </li>
            <li>
              <span  class="sonsume-conspan">{{$t('env.pright')}}</span>
              <span class="consume-num">{{envcontent.reduceton}}</span>
              <span class="consume-detail">{{$t('env.achnumContent')}}</span>
            </li>
          </ul>

        </div>
        <!-- 零碳先锋 -->
        <div class="carbon feature envCon">
          <div class="carbon-content">
            <h4>{{$t('env.carbontitle')}}</h4>
            <p>{{$t('env.carbonContent')}}</p>
          </div>
          <div class="fbanner" style="padding-top: 20px">
            <swiper v-bind:options="swiperOption" >
              <swiper-slide v-for="(item,index) in childrenList" v-bind:key="index">
                <img :src="item.imgUrl">
              </swiper-slide>

            </swiper>

            <!-- Add Pagination -->
            <div class="swiper-pagination"></div>
          </div>
        </div>
        <!-- 能源突破 -->
        <div class="carbon feature envCon">
          <div class="carbon-content">
            <h4>{{$t('env.enerytitle')}}</h4>
            <p>{{$t('env.enerycontent')}}</p>
          </div>
          <div class="fbanner" style="padding-top: 20px">
            <img src="/img/foundation/emery.jpg" alt="">
          </div>
        </div>
        <!-- 绿色开发 -->
        <div class="dev envCon feature">
          <h4>{{$t('env.greendev')}}</h4>

          <div class="dev-container">
            <p>{{$t('env.greencontent')}}</p>
            <div class="dev-detail">

              <span class="num">{{envcontent.certarea}}</span>
              <span class="dev-detail-text">{{$t('env.devnumdetail')}}</span>
              <span class="dev-detail-span">{{$t('env.devnumcontent')}}</span>
            </div>
            <div class="dev-detail dev-detail1">
              <span class="num">{{envcontent.arearate}}%</span>
              <span class="dev-detail-span">{{$t('env.devnumcontent1')}}</span>
            </div>
          </div>
          <div class="dev-img">
            <img src="/img/env/gold.png" alt="">
          </div>

        </div>
        <div class="manager envCon feature">
          <h4>{{$t('env.opentitle')}}</h4>
          <p>{{$t('env.opencontent')}}</p>
          <div class="manager-texts">
            <span style="display: block;">{{$t('env.opendetail')}}</span>
            <div class="env-num"     style="display: flex;justify-content: center; align-items: flex-end">
              <span class="num">100%</span>
              <span class="manager-text"  v-if="this.$i18n.locale == 'zh'">平台化</span>
              <div  v-if="this.$i18n.locale == 'en'" style="display: flex;flex-direction: column;text-align: left;font-size: 20px;" >
                <span class="manager-text">Platform</span>
                <span class="manager-text">Management</span>
              </div>

            </div>

          </div>
        </div>
        <div class="honour envCon feature">
          <h4 >{{$t('env.honourtitle')}}</h4>
          <p>{{$t('env.honourhead')}}</p>
          <p>{{$t('env.honourtext')}}</p>
          <div class="num">{{envcontent.bonus}} <span class="strong-child">{{$t('env.honourunit')}}</span></div>

          <ul class="record">
            <li class="feature" v-for="(item,index) in memorabiliaList" :key="index">
              <div class="record-left">
                {{item.date}}
              </div>
              <div class="record-right">
                <span>
                  {{item.content}}
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import NavHeader from "../components/Header"
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import {mapState} from 'vuex'
import { getdataInfo } from "../api/home";
export default {
  name: "Env",
  data() {
    return{
      id: 2,
      pageType: 'env',
      envcontent:{},
      memorabiliaList:[],
      childrenList:[
        {
          id:"1",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib1.jpg"
        },
        {
          id:"2",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib2.jpg"
        },

        {
          id:"5",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib3.jpg"
        },
        {
          id:"6",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib4.jpg"
        },
        {
          id:"7",
          imgUrl:"https://sohochina.oss-cn-beijing.aliyuncs.com/ESG/lib5.jpg"
        }

      ],
      swiperOption:{
        loop: true, // 循环模式选项
        autoplay:true,
        delay: 1000,
        pagination: {
          el: '.swiper-pagination',
        }
      },
    }
  },
  components :{
    NavHeader,
    Swiper,
    SwiperSlide,
  },
  mounted() {
    const staggeringOption = {
      delay: 300,
      distance: "50px",
      duration: 500,
      easing: "ease-in-out",
      origin: "bottom",
    };

    this.$scroll().reveal(".feature", { ...staggeringOption, interval: 350 });
    this.getdata()
  },

  methods:{
    async getdata() {
      const data = await getdataInfo({ id: this.id, pageType: this.pageType })
      const zh_envdata= JSON.parse(data.esgContent.cnContent)
      const en_envdata= JSON.parse(data.esgContent.enContent)

      if(this.$i18n.locale=='zh'){
        this.envcontent = zh_envdata
        this.memorabiliaList = this.envcontent.memorabiliaList.reverse()
      }else {
        this.envcontent = en_envdata
        this.memorabiliaList = this.envcontent.memorabiliaList.reverse()
      }
    },
    handlerdata(){
      this.getdata()
    },
  },
  computed:{
    ...mapState({
      lang:state =>state.lang
    })
  }
}
</script>

<style  scoped lang="scss">
/deep/.swiper-pagination-bullet{
  width:12px;
  height: 12px;
  background-color: #ffffff;
  margin-left: 10px;
  opacity: 1;
}
/deep/.swiper-pagination{
  width: 1080px;
  margin: 0 auto;
  text-align: center;
  margin-top: -30px;
}
/deep/.swiper-pagination-bullet-active{
  background-color: grey;

}
.main{
  width: 100%;
  background-color: #F5F5F7;
  padding-top: 68px;
  padding-bottom:100px;
  .zh h4{
    font-size: 64px;
    text-align: center;
  }

  #fonts{
    font-size: 16px;
    margin-left: -8px;
  }
  .en #fonts{
    font-size: 12px;
    margin-left: -3px;
  }
  .en h4 {
    font-size:46px;
    font-family: "BarlowCondensed-bold";
    text-align: center;
  }

  p{
    color: #353535;
    text-align: justify;
  }
  .en p {
    font-family: "Barlow-Regular";
  }
  .target{
    display: flex;
    justify-content: space-between;

  }
  .feature-margin-left{
    padding-left:0px;
    margin-top: 70px;
  }
  .en .feature-margin-left{
    padding-left: 0px;
    margin-top: 84px;
  }
  .target .pub{
    width: 47%;
    height:605px;
    background: #FFF;
    border-radius: 30px;
    box-sizing: border-box;
    padding-top: 94px;

  }
  .en .target {
    font-family: "BarlowCondensed-bold";
  }
  .target .pub h4{
    color:rgb(34, 195, 173);
    font-weight: bold;
  }
  .target span{
    font-size: 24px;
  }
  .target .num{
    font-size: 72px;
    font-family:"BarlowCondensed-bold";
    font-weight: bold;
  }
  .target .target-detail{
    box-sizing: border-box;
    float: left;
    width: 50%;
    padding-left: 10%;
  }


  .target .target-detail-bottom {
    padding-top: 40px;
    width: 100%;
  }
  .en .target .target-detail-bottom span:nth-child(1) {
    font-size: 16px;
    font-family: "Barlow-Regular";
  }
  .target .target-detail-text{
    font-size: 36px;
  }
  .en  .target .target-detail-text{
    font-size: 22px;
    font-family: "BarlowCondensed-bold";
  }
  .target-detail-span{
    display: block;
    margin-top:-8px;
    margin-left: 0px;
  }
  .en .target-detail-span{
    font-family: "Barlow-Regular";
    font-size: 16px;
  }
  //.target-detail-span1{
  //  margin-left: -30px;
  //}
  //.en .target-detail-span1{
  //  margin-left:16px;
  //}

  /* 右边布局 */
  .target-right {
    //text-align: center;
  }

  .target-right-img{
    width: 100%;
    text-align: center;
  }
  .target-right-img img{
    width: 104px;
    height: 104px;
    margin: 54px 0;

  }
  .en  .target-right img{
    margin: 0;
    margin-top: 100px;
    margin-bottom: 50px;
  }
  .target-right-year{
    display: block;
    width: 100%;
    text-align: center;
  }


  /* 总耗能 */
  .consumeEnery{
    margin-top:40px ;
    background-color: #FFF;
    border-radius:30px;
    padding-top: 90px;
    padding-bottom: 100px;
    font-size: 28px;
  }
  .consumeEnery-title{
    text-align: center;
  }
  .consumeEnery-title h4{
    color:rgb(34, 195, 173);
    font-weight: bold;
  }
  .consumeEnery-title p{
    font-size: 48px;
    font-family: "Barlow-Regular";
    text-align: center;
  }
  .en .consumeEnery-title p{
    font-size: 30px;
    margin-top: 10px;
  }
  .sonsume-conspan{
    display:block;
    font-size: 28px;
    margin-bottom: -12px;
  }
  .en .consumeEnery-total .total-conspan{
    font-size: 24px;
    font-family: "Barlow-Regular";
  }
  .en .sonsume-conspan{
    font-size: 18px;
    font-family: "Barlow-Regular";
  }


  .consumeEnery-total{
    text-align: center;
    padding-top: 110px;
  }

  .consume-num{
    font-size: 72px;
    font-family:"BarlowCondensed-bold";
    letter-spacing: 2px;
  }
  .consume-detail{
    font-size: 36px;
  }
  .en  .consume-detail{
    font-size: 22px;
    font-family: "BarlowCondensed-bold";
  }
  .consumeEnery-detail{
    padding-top: 120px;
    display: flex;
    justify-content: space-around;
  }
  /*零碳先锋*/
  .en .carbon {
    p{
      font-size: 18px;
    }
  }
  .carbon{
    margin-top: 40px;
    background: #ffffff;
    padding-top: 90px;
    border-radius: 30px;
    img{
      display: block;
      width: 100%;
      height:690px;
    }
    h4{
      color: #22c3ad;
      font-weight: bold;
      padding-bottom: 35px;
    }
    p{
      letter-spacing: 1px;
      line-height: 46px;
      font-size: 24px;
      padding:0px 120px;
      color: #353535;
    }
  }

  /* 绿色开发模块 */
  .dev{
    margin-top: 60px;
    margin-bottom: 30px;
    background: #FFF;
    border-radius: 30px;
    padding-top: 90px;
  }
  .dev h4{
    color:rgb(34, 195, 173);
    font-weight: bold;

  }
  .dev .dev-container{
    padding: 32px 120px 0;
  }
  .dev .dev-container p{
    letter-spacing: 1px;
    line-height:46px;
    font-size: 24px;

  }
  .en  .dev .dev-container p{
    font-size: 18px;

  }

  .dev .dev-detail{
    padding-top: 20px;
    box-sizing: border-box;
    float: left;
    width: 50%;

  }
  .dev .dev-detail1{
    text-align: left;
    box-sizing: border-box;
    padding-left: 76px;
  }



  .dev .num{
    font-size: 72px;
    font-family:"BarlowCondensed-bold";

  }
  .dev .dev-detail-text{
    font-size: 36px;
  }
  .en .dev .dev-detail-text{
    font-size: 22px;
    font-family: "BarlowCondensed-bold";
  }
  .dev-detail-span{
    display: block;
    margin-top:2px;

    font-size: 24px;
  }
  .en .dev-detail-span{
    font-family: "Barlow-Regular";
  }
  .dev-img{
    width: 100%;
    text-align: center;
  }
  .dev img{
    margin-top: 96px;
    width: 530px;
    height: 250px;
  }

  /* 运营 */
  .manager{
    padding: 90px 0 80px;
    background:#22c3ad;
    border-radius: 30px;
  }

  .manager h4{
    color: #fff;;
    font-weight: bold;
  }
  .manager p{

    width: 100%;
    font-size: 28px;
    box-sizing: border-box;
    color: #FFF;
    padding:0px 120px;
    padding-top: 40px;
    letter-spacing: 1px;
    line-height: 46px;
    opacity: 0.8;
  }
  .en .manager p{
    font-size: 18px;
    opacity: 1;
  }
  .manager-texts{
    padding-top: 65px;
    width: 100%;
    text-align: center;

  }
  .manager-texts span{
    color: #FFF;
    font-size: 24px;

  }

  .manager-texts .num{
    font-size: 72px;
    font-family:"BarlowCondensed-bold";
    letter-spacing: 2px;
  }
  .manager-texts .manager-text{
    font-size: 36px;
  }
  .en .manager-texts .manager-text{
    font-size: 22px;
  }

  /* 荣耀 */
  .honour{
    padding-top: 100px;

  }
  .honour p {

    font-size:28px;
    letter-spacing: 2px;
    text-align: center;
  }
  .en .honour p {
    font-size: 18px;
    text-align: left;
    padding-left: 150px;
  }
  .honour h4{
    color:rgb(34, 195, 173);
    font-weight: bold;
    padding-bottom: 60px;
  }
  .honour .num{
    width: 100%;
    text-align: center;
    display: block;
    font-size: 72px;
    font-family:"BarlowCondensed-bold";

  }
  .honour .num .strong-child{
    font-size: 40px;
  }
  /* .record{
      padding-top: 150px;
  } */
  .record{

    padding-left:180px;
    padding-top: 60px;


  }
  .record li{
    padding-top: 50px;
    display: flex;
    flex-direction:row;
    align-items: center;

  }

  .record-right span{
    font-size: 18px;
    display: block;
    line-height: 1.5;
  }
  .en .record-right span{
    font-size: 16px;
    line-height: 1.5;
    font-family: "Barlow-Regular";
  }
  .record-left{
    font-size: 64px;
    color: #888888;
    font-family:"BarlowCondensed-bold";
  }
  .record-right{
    margin-left: 20px;
    letter-spacing: 2px;
  }
  span{
    color: #353535;
  }

}


@media(max-width:680px) {
  .swiper-pagination{
    width: 100%;

  }
  .main {
    width: 100%;
    background-color: #F5F5F7;
    padding-top: 34px;
    padding-bottom: 50px;
    margin: 0 auto;

    .zh h4{
      font-size: 42px;
    }
    .en h4{
      font-size: 36px;
    }
    .feature-margin-left {
      padding-left:0px;
      margin-top: 50px;
    }
    .en .target-detail-span{
      font-size: 12px;
    }
    .en p{
      text-align: left;
    }
    .en .record-right span{
      font-size: 14px;
      text-align: left;
      letter-spacing: 0;
    }
    .en .honour p{
      font-size: 16px;
    }
    .target {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .target .pub {
      width: 90%;
      margin: 0 auto;
      height: 360px;
      background: #FFF;
      border-radius: 15px;
      box-sizing: border-box;
      padding-top: 35px;

    }

    .target .pub h4 {
      color: rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;
    }

    .target span {
      font-size: 18px;
    }

    .target .num {
      font-size: 40px;
      font-family: "BarlowCondensed-bold";
      font-weight: bold;
    }
    .en .feature-margin-left{
      margin-top: 10px;
    }
    .target .target-detail {
      box-sizing: border-box;
      float: left;
      width: 50%;
      text-align: left;
      padding-left:5%;

    }
    .target .mobile-target{
      text-align: center;
      padding-left: 0;
    }

    .target .target-detail-bottom {
      padding-top: 30px;
      width: 100%;
    }

    .target .target-detail-text {
      font-size: 22px;
    }

    .target-detail-span {
      display: block;
      margin-top: 0px;
      margin-left: 0px;
    }

    /* 右边布局 */
    .target-right {
      text-align: center;

    }

    #target-right-margin {
      margin-top: 30px;
    }

    .target-right img {
      width: 62px;
      height: 62px;
      margin: 32px 0;
    }
    .en .target-right img{
      margin: 36px 0;
    }
    .target-right-year {
      display: block;
      width: 100%;
      text-align: center;
    }


    /* 总耗能 */
    .consumeEnery {
      width: 90%;
      margin-top: 40px;
      background-color: #FFF;
      border-radius: 10px;
      padding-top: 35px;
      padding-bottom: 30px;
      font-size: 18px;
      text-align: center;
    }

    .consumeEnery-title {
      text-align: center;
    }

    .consumeEnery-title h4 {
      color: rgb(34, 195, 173);
      font-weight: bold;
    }

    .consumeEnery-title p {
      font-size: 18px;
      font-family: "Barlow-Regular";
      text-align: center;
      color: #353535;
    }
    .en .consumeEnery-title p{
      font-size: 18px;
    }
    .sonsume-conspan {
      display: block;
      font-size: 22px;
      margin-bottom: -12px;
    }

    .consumeEnery-total {
      text-align: center;
      padding-top: 40px;
    }

    .consume-num {
      font-size: 48px;
      font-family: "BarlowCondensed-bold";

    }

    .consume-detail {
      font-size: 24px;
    }

    .consumeEnery-detail {
      padding-top: 0px;
      display: flex;
      flex-direction: column;
    }

    .consumeEnery-detail li {
      padding-top: 20px;
    }
    .en .carbon{
      p{
        font-size: 14px;
      }
    }
    /*零碳先锋*/
    .carbon{
      padding-top: 40px;
      p{
        padding: 0 20px;
        font-size: 14px;
        line-height: 1.5;
      }
      img{
        height: auto;
      }
    }

    /* 绿色开发模块 */
    .dev {
      width: 90%;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 15px;
      background: #FFF;
      border-radius: 15px;
      padding-top: 35px;
    }

    .dev h4 {
      color: rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;

    }

    .dev .dev-container {
      padding: 0;
      padding-top: 30px;
    }

    .dev .dev-container p {
      letter-spacing: 1px;
      line-height: 1.5;
      font-size: 16px;
      padding: 0 15px;
    }
    .en .dev .dev-container p{
      font-size: 14px;
      letter-spacing: 0px;
    }

    .dev .dev-detail {
      padding-top: 20px;
      box-sizing: border-box;
      float: none;
      width: 100%;
      text-align: center;
      padding-left: 0;
    }


    .dev .num {
      font-size: 40px;
      font-family: "BarlowCondensed-bold";

    }

    .dev .dev-detail-text {
      font-size: 24px;
    }

    .dev-detail-span {
      display: block;
      margin-top: 2px;
      margin-left: 7px;
      font-size: 20px;
    }

    .dev-img {
      width: 90%;
      margin: 0 auto;
      text-align: center;
    }

    .dev img {
      margin-top: 96px;
      width: 100%;
      height: auto;
    }

    /* 运营 */
    .manager {
      width: 90%;
      margin: 35px auto 0;
      padding-boottom: 25px;
      background: #22c3ad;
      border-radius: 30px;
    }

    .manager h4 {

      color: #fff;;
      font-weight: bold;
      text-align: center;
    }

    .manager p {
      width: 100%;
      font-size: 18px;
      box-sizing: border-box;
      color: #FFF;
      padding: 0px 15px;
      padding-top: 40px;
      letter-spacing: 1px;
      line-height: 1.5;
      opacity: 0.8;
    }
    .en .manager p{
      font-size: 14px;
    }

    .manager-texts {
      padding-top: 65px;
      width: 100%;
      text-align: center;

    }

    .manager-texts span {
      color: #FFF;
      font-size: 24px;
    }
    .en .manager-texts span{
      font-size: 16px;
    }
    .manager-texts .num {
      font-size: 42px;
      font-family: "BarlowCondensed-bold";
    }
    .en .manager-texts .num {
      font-size: 42px;

    }
    .manager-texts .manager-text {
      font-size: 26px;
    }

    /* 荣耀 */
    .honour {
      padding-top: 90px;
      width: 90%;
      margin: 0 auto;
    }

    .honour p {

      font-size: 20px;
      letter-spacing: 2px;
      text-align: left;
    }
    .en .honour p{
      padding-left: 0;
      font-size: 16;
    }

    .honour h4 {
      font-size: 40px;
      color: rgb(34, 195, 173);
      font-weight: bold;
      text-align: center;
      padding-bottom: 35px;
    }

    .honour .num {
      width: 100%;
      text-align: center;
      display: block;
      font-size: 50px;
      font-family: "BarlowCondensed-bold";

    }

    .honour .num .strong-child {
      font-size: 30px;
      font-family: "BarlowCondensed-bold";
    }

    /* .record{
      padding-top: 150px;
  } */
    .record {
      padding-left: 10px;
      padding-top: 30px;
    }

    .record li {
      padding-top: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;

    }

    .record-right span {
      font-size: 14px;
      display: block;
      line-height: 1.5;
      text-align: justify;

    }

    .record-left {
      font-size: 50px;
      color: #888888;
      font-family: "BarlowCondensed-bold";
    }

    .record-right {

      margin-left: 20px;
      letter-spacing: 2px;
    }
  }
}
</style>
